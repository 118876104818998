import React from 'react'
import dayjs, { Dayjs } from 'dayjs';

interface Props {
    value: Dayjs,
    onChange: (value: Dayjs) => void
}

const DATE_INPUT_FORMAT = "YYYY-MM-DD"

export function DateSelector(props: Props) {
    return (
        <div>
            <input
                className="input"
                type="date"
                min={dayjs().format(DATE_INPUT_FORMAT)}
                value={props.value.format(DATE_INPUT_FORMAT)}
                onChange={(event) => {
                    const value = dayjs(event.target.value)
                    props.onChange(value)
                }} />
        </div >
    )
}