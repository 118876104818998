/**
 * @generated SignedSource<<0a8d9e9f5d33616be4f3ff10ce1438ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserNotificationPreferences$data = {
  readonly checkInNotificationEmail: boolean;
  readonly checkInNotificationSlack: boolean;
  readonly " $fragmentType": "UserNotificationPreferences";
};
export type UserNotificationPreferences$key = {
  readonly " $data"?: UserNotificationPreferences$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserNotificationPreferences">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNotificationPreferences",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInNotificationEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkInNotificationSlack",
      "storageKey": null
    }
  ],
  "type": "UserNotificationPreferences",
  "abstractKey": null
};

(node as any).hash = "65ce2f96238575cce3a500bbbeb97d46";

export default node;
