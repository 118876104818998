import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../App"
import { AuthenticationStatusContext } from "../../config/AuthConfigurator"

interface Props {
    onClick?: () => void
}

export function SignOutButton(props: Props) {
    const authenticationStatus = useContext(AuthenticationStatusContext)
    const navigate = useNavigate()
    return (
        <button onClick={() => {
            props.onClick?.()
            navigate(ROUTES.home.path)
            authenticationStatus.setToken(null)
        }} className="button is-text">
            Sign Out
        </button>
    )
}