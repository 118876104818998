import React from 'react'
import { useFragment } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { ReservationDetails$key } from './__generated__/ReservationDetails.graphql'
import { CheckInButton } from './CheckInButton'


interface Props {
    reservationKey: ReservationDetails$key,
    userId: string,
}

export function ReservationDetails(props: Props) {
    const data = useFragment(
        graphql` 
            fragment ReservationDetails on Reservation {
                id
                user {
                    id
                    name
                }
                day {
                    day
                    month
                    year
                }
                checkedIn
                checkInNotificationSent

            } 
        `,
        props.reservationKey
    )

    if (data.user.id !== props.userId) {
        return <div><b>Reserved by:</b> {data.user.name}</div>
    }

    if (!data.checkInNotificationSent) {
        return <div>You have reserved this desk</div>
    }

    if (!data.checkedIn) {
        return <CheckInButton reservationId={data.id} />
    }

    return <div>You're checked in!</div>
}