import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from "react-relay"
import { OfficeSelector$key } from "./__generated__/OfficeSelector.graphql"
import { useMemo, useRef, useState } from "react"
import { ROUTES } from '../../App'
import { useOutsideClick } from '../../util/useOutsideClick'

interface Props {
    officeId: string,
    companyKey: OfficeSelector$key
}

export function OfficeSelector(props: Props) {
    const [open, setOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setOpen(false),
    });

    const data = useFragment(
        graphql`
            fragment OfficeSelector on Company {
                offices {
                    id
                    name
                }
            }
        `,
        props.companyKey
    )

    const officeById = useMemo(() => {
        return new Map(data.offices.map(office => [office.id, office]))
    }, [data])

    const selectedOffice = officeById.get(props.officeId)

    if (selectedOffice == null) {
        throw new Error(`Unrecognized office id: ${props.officeId}`)
    }

    return (
        <div className={"dropdown" + (open ? " is-active" : "")} ref={dropdownRef}>
            <div className="dropdown-trigger">
                <button
                    className="button"
                    onClick={() => setOpen(!open)}
                    aria-haspopup="true"
                    aria-controls="dropdown-menu">
                    <span>{selectedOffice.name}</span>
                    <span className="icon is-small">
                        <span className="material-symbols-outlined" aria-hidden="true">
                            {open ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                        </span>
                    </span>
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {data.offices.map(office => (
                        <a
                            href={ROUTES.office.path(office.id)}
                            key={office.id}
                            className={"dropdown-item" + (office.id === props.officeId ? " is-active" : "")}
                        >
                            {office.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}