import { graphql } from "babel-plugin-relay/macro"
import { useMutation } from "react-relay"
import { CheckInButtonMutation } from "./__generated__/CheckInButtonMutation.graphql"

interface Props {
    reservationId: string,
}

export function CheckInButton(props: Props) {
    const [checkIn, pending] = useMutation<CheckInButtonMutation>(
        graphql`
            mutation CheckInButtonMutation(
                $reservationId: String!
                ) {
                checkInReservation(reservationId: $reservationId) {
                    id
                    checkedIn
                }
            } 
        `
    )

    return (
        <button
            className="button"
            onClick={() => checkIn({
                variables: {
                    reservationId: props.reservationId
                },
            })}
            disabled={pending}
        >
            Check In
        </button>
    )
}