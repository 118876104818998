import { graphql } from "babel-plugin-relay/macro"
import { useLazyLoadQuery, useMutation } from "react-relay"
import { CheckInMutation } from "./__generated__/CheckInMutation.graphql"
import { CheckInQuery } from "./__generated__/CheckInQuery.graphql"

interface Props {
    reservationId: string
}

graphql`
    fragment CheckInReservation on Reservation {
        id
        checkedIn
        desk {
            name
            office {
                name
            }
        }
        day {
            day
            month
            year
        }
    }
`

export function CheckIn(props: Props) {
    const data = useLazyLoadQuery<CheckInQuery>(
        graphql`
            query CheckInQuery($reservationId: String!) {
                reservation(id: $reservationId) {
                    ...CheckInReservation @relay(mask: false)
                }
            }
        `,
        {
            reservationId: props.reservationId
        }
    )

    const [checkIn, pending] = useMutation<CheckInMutation>(
        graphql`
            mutation CheckInMutation(
                $reservationId: String!,
                ) {
                checkInReservation(reservationId: $reservationId) {
                    ...CheckInReservation @relay(mask: false)
                }
            } 
        `
    )

    if (data.reservation == null) {
        return <div>
            Reservation not found!
        </div>
    }

    return (
        <div>
            Confirming reservation for desk {data.reservation.desk.name}{" "}
            in {data.reservation.desk.office.name}{" "}
            on {data.reservation.day.day}-{data.reservation.day.month}-{data.reservation.day.year}

            {data.reservation.checkedIn ?
                (
                    <div>
                        You're all checked in!
                    </div>
                ) : (
                    <button
                        className="button"
                        onClick={() => checkIn({
                            variables: {
                                reservationId: props.reservationId
                            },
                        })}
                        disabled={pending}
                    >
                        Check In
                    </button>
                )
            }
        </div>
    )
}