import { Suspense } from "react";
import { AuthRequired } from "../auth/AuthRequired";
import { UserSettings } from "../components/userSettings/UserSettings";

export function UserSettingsPage() {
    return (
        <AuthRequired>
            <Suspense fallback={"loading..."}>
                <UserSettings />
            </Suspense>
        </AuthRequired>
    )
}