import dayjs, { Dayjs } from "dayjs"

export interface DateRange {
    start: Dayjs,
    end: Dayjs
}


export function mapDateRange<R>(range: DateRange, func: (day: Dayjs) => R) {
    if (range.end.isBefore(range.start)) {
        console.error("Attempting to iterate over date range where the end is before the start")
    }
    let result = []
    for (let d = range.start; d.isBefore(range.end) || d.isSame(range.end); d = d.add(1, "day")) {
        result.push(func(d))
    }
    return result
}

export function parseGqlDay(day: {
    day: number,
    month: number,
    year: number
}): Dayjs {
    return dayjs(new Date(
        day.year,
        // Dayjs zero indexes months
        day.month - 1,
        day.day))
}

export function dayToGql(day: Dayjs) {
    return {
        // Dayjs.date() returns the day of the month. Not the full date!
        day: day.date(),
        // Dayjs zero indexes months
        month: day.month() + 1,
        year: day.year(),
    }
}

export function dateRangeToGql(dateRange: DateRange) {
    return {
        start: dayToGql(dateRange.start),
        end: dayToGql(dateRange.end),
    }
}