import { ReactElement, createContext, useState } from "react";
import { TokenData, parseToken } from "../auth/Auth";

interface Props {
    children: ReactElement
}

export interface AuthenticationStatus {
    setToken: (token: string | null) => void,
    tokenData: TokenData | null,
}

export const AuthenticationStatusContext = createContext<AuthenticationStatus>({
    setToken: () => { },
    tokenData: null
})

const LOCAL_STORAGE_TOKEN_KEY = "deskinnAuthToken"

export function AuthConfigurator(props: Props) {
    const [tokenData, setTokenData] = useState<null | TokenData>(() => {
        const storedToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
        if(storedToken == null) {
            return null
        }
        const parsedToken = parseToken(storedToken)
        if(parsedToken.expiresAt < new Date()) {
            localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
            return null
        }
        return parsedToken
    })

    return (
        <AuthenticationStatusContext.Provider value={{
            setToken: (newToken => {
                if (newToken == null) {
                    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)
                    setTokenData(null)
                } else {
                    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, newToken)
                    const parsedToken = parseToken(newToken)
                    setTokenData(parsedToken)
                }
            }),
            tokenData: tokenData
        }}>
            {props.children}
        </AuthenticationStatusContext.Provider>
    )
}
