import { Navbar } from "../components/Navbar";
import { Outlet } from "react-router-dom";

export function Root() {
    return (
        <div style={{ height: "100%" }}>
            <Navbar />
            <Outlet />
        </div>
    )
}