import { useFragment } from "react-relay"
import { graphql } from 'babel-plugin-relay/macro'
import { DeskDetailsModal$key } from "./__generated__/DeskDetailsModal.graphql"
import { Dayjs } from "dayjs"
import { ReserveDeskButton } from "../reservation/ReserveDeskButton"
import { ReservationDetails } from "../reservation/ReservationDetails"

interface Props {
    deskKey: DeskDetailsModal$key,
    day: Dayjs,
    userId: string,
    reservedDesk: {
        id: string,
        name: string,
    } | null
    onClose: () => void,
}

export function DeskDetailsModal(props: Props) {
    const data = useFragment(
        graphql`
            fragment DeskDetailsModal on Desk
            @argumentDefinitions(
                day: {
                  type: "DayInput!"
                }
              ) {
                id 
                name
                reservation(day: $day) {
                    ...ReservationDetails
                }
            }
        `,
        props.deskKey
    )

    return (
        <div className="modal is-active" style={{ zIndex: 200 }}>
            <div className="modal-background" onClick={props.onClose} />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">
                        <b>Desk:</b> {data.name}
                    </p>
                    <button className="delete" aria-label="close" onClick={props.onClose} />
                </header>
                <footer className="modal-card-foot is-flex is-justify-content-end">
                    <div className="buttons">
                        {data.reservation == null ?
                            (props.reservedDesk != null ? (<div>
                                You've already reserved desk {props.reservedDesk.name} on this day.
                                You can only reserve one desk per day
                            </div>)
                                : <ReserveDeskButton deskId={data.id} day={props.day} />) :
                            <ReservationDetails reservationKey={data.reservation} userId={props.userId} />
                        }
                    </div>
                </footer>
            </div>
        </div>
    )
}