/**
 * @generated SignedSource<<3e03918b922e0b44c13d85b691d92ef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeskDetailsModal$data = {
  readonly id: string;
  readonly name: string;
  readonly reservation: {
    readonly " $fragmentSpreads": FragmentRefs<"ReservationDetails">;
  } | null;
  readonly " $fragmentType": "DeskDetailsModal";
};
export type DeskDetailsModal$key = {
  readonly " $data"?: DeskDetailsModal$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeskDetailsModal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "day"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeskDetailsModal",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "day",
          "variableName": "day"
        }
      ],
      "concreteType": "Reservation",
      "kind": "LinkedField",
      "name": "reservation",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReservationDetails"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Desk",
  "abstractKey": null
};

(node as any).hash = "7f47835db0c6c333618bfd0304728f0f";

export default node;
