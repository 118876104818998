export function HomePage() {
    const mailto = "mailto:alex@deskinn.com"
    return <div>
        <section className="hero">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="block">
                        <p className="title">Better Desk Hoteling For Your Hybrid Workers</p>
                    </div>
                    <div className="block">
                        <p className="subtitle">Book a desk, check in, and get to work</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="section has-text-centered">
            <div className="block">
                Deskinn is still a work in progress, but don't be afraid to <a href={mailto}>get in touch</a>
            </div>
        </section>
    </div>
}