/**
 * @generated SignedSource<<066bab3b697614d813bc337c17ef4310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetUserNotificationPreferencesRequestInput = {
  checkInNotificationEmail: boolean;
  checkInNotificationSlack: boolean;
};
export type UserNotificationPreferencesMutation$variables = {
  input: SetUserNotificationPreferencesRequestInput;
};
export type UserNotificationPreferencesMutation$data = {
  readonly setUserNotificationPreferences: {
    readonly " $fragmentSpreads": FragmentRefs<"UserNotificationPreferences">;
  };
};
export type UserNotificationPreferencesMutation = {
  response: UserNotificationPreferencesMutation$data;
  variables: UserNotificationPreferencesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "request",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationPreferences",
        "kind": "LinkedField",
        "name": "setUserNotificationPreferences",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserNotificationPreferences"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotificationPreferencesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationPreferences",
        "kind": "LinkedField",
        "name": "setUserNotificationPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkInNotificationEmail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkInNotificationSlack",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0b81b2267a10bad4e3f6c9d50bb8243",
    "id": null,
    "metadata": {},
    "name": "UserNotificationPreferencesMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotificationPreferencesMutation(\n  $input: SetUserNotificationPreferencesRequestInput!\n) {\n  setUserNotificationPreferences(request: $input) {\n    ...UserNotificationPreferences\n  }\n}\n\nfragment UserNotificationPreferences on UserNotificationPreferences {\n  checkInNotificationEmail\n  checkInNotificationSlack\n}\n"
  }
};
})();

(node as any).hash = "e84e8881a0f7a7c73fd4f15829164203";

export default node;
