/**
 * @generated SignedSource<<fdc41ff732f72f3b07ab37431e764a64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CheckInButtonMutation$variables = {
  reservationId: string;
};
export type CheckInButtonMutation$data = {
  readonly checkInReservation: {
    readonly checkedIn: boolean;
    readonly id: string;
  } | null;
};
export type CheckInButtonMutation = {
  response: CheckInButtonMutation$data;
  variables: CheckInButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reservationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reservationId",
        "variableName": "reservationId"
      }
    ],
    "concreteType": "Reservation",
    "kind": "LinkedField",
    "name": "checkInReservation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkedIn",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInButtonMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckInButtonMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "62a97ce7de7bb8b22c9a155285c3fac2",
    "id": null,
    "metadata": {},
    "name": "CheckInButtonMutation",
    "operationKind": "mutation",
    "text": "mutation CheckInButtonMutation(\n  $reservationId: String!\n) {\n  checkInReservation(reservationId: $reservationId) {\n    id\n    checkedIn\n  }\n}\n"
  }
};
})();

(node as any).hash = "eeb830e9ec120eb01ccc171cb17f775e";

export default node;
