import jwtDecode from "jwt-decode"

export interface LoginRequest {
    username: string,
    password: string
}

// Data that is available when the user is logged in
export interface TokenData {
    token: string,
    userId: string,
    userName: string,
    companyId: string,
    expiresAt: Date,
}

interface TokenSchema {
    aud: string,
    iss: string,
    userId: string,
    userName: string,
    companyId: string,
    exp: number,
}

export type GetTokenResponse = GetTokenSuccessResponse | GetTokenErrorResponse

export interface GetTokenSuccessResponse {
    token: string
}

export interface GetTokenErrorResponse {
    error: "invalid_credentials" | "internal_error"
}

export async function getToken(request: LoginRequest): Promise<GetTokenResponse> {
    try {
        const resp = await fetch('/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username: request.username,
                //TODO salt this
                password: request.password
            })
        })

        return await resp.json()
    } catch (e) {
        return { error: "internal_error" }
    }
}

export function parseToken(token: string): TokenData {
    const tokenData = jwtDecode<TokenSchema>(token)

    return {
        token: token,
        userId: tokenData.userId,
        userName: tokenData.userName,
        companyId: tokenData.companyId,
        // The token `exp` is in seconds. Javascript's date expects milliseconds
        expiresAt: new Date(tokenData.exp * 1000)
    }
}
