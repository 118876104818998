import React, { useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay'
import { DeskPin$key } from './__generated__/DeskPin.graphql'
import { AuthenticationStatus, AuthenticationStatusContext } from '../../config/AuthConfigurator'
import { UserIcon } from '../../visualComponents/UserIcon'

interface Props {
    deskKey: DeskPin$key,
    onClick: () => void,
}

export interface DeskStatus {
    booked: boolean,
    bookedByUser: boolean
}

const USER_ICON_SIZE = 30;

export function DeskPin(props: Props) {
    const data = useFragment(
        graphql`
            fragment DeskPin on Desk
            @argumentDefinitions(
                day: {
                  type: "DayInput!"
                }
              ) {
                name
                reservation(day: $day) {
                    id
                    user {
                        id
                        name
                    }
                }
            }
        `,
        props.deskKey
    )

    const authData = useContext<AuthenticationStatus>(AuthenticationStatusContext)

    const deskStatus: DeskStatus = {
        booked: data.reservation != null,
        bookedByUser: data.reservation?.user.id === authData.tokenData?.userId,
    }

    const reservationUserName = data.reservation?.user.name

    return (
        <button
            className={calculateDeskClassName(deskStatus)}
            style={{ height: 40 }}
            onClick={props.onClick} >
            {reservationUserName && (
                <div style={{ position: "relative", right: `${USER_ICON_SIZE / 2}px` }}>
                    <UserIcon userName={reservationUserName} sizePx={USER_ICON_SIZE} />
                </div>)
            }
            {data.name}
        </button >
    )
}

function calculateDeskClassName(status: DeskStatus) {
    if (status.bookedByUser) {
        return "button is-rounded is-success"
    }
    if (status.booked) {
        return "button is-rounded is-danger"
    }
    return "button is-rounded"
}
