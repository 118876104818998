import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { Configurator } from './config/Configurator';
import { RelayConfigurator } from './config/RelayConfigurator';
import { AuthConfigurator } from './config/AuthConfigurator';
import { LoginPage } from './pages/LoginPage';
import { OfficePage } from './pages/OfficePage';
import { UserHomePage } from './pages/UserHomePage';
import "./App.css"
import { UserSettingsPage } from './pages/UserSettingsPage';
import { SlackRedirectPage } from './pages/SlackRedirectPage';
import { Root } from './pages/Root';
import { NoPageFoundPage } from './pages/NoPageFoundPage';
import { CheckInPage } from './pages/CheckInPage';
import { ToastConfigurator } from './config/ToastConfigurator';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        { path: ROUTES.home.path, element: <HomePage /> },
        { path: ROUTES.login.path, element: <LoginPage /> },
        { path: ROUTES.userHome.path, element: <UserHomePage /> },
        { path: ROUTES.userSettings.path, element: <UserSettingsPage /> },
        { path: ROUTES.office.path(":officeId"), element: <OfficePage /> },
        { path: ROUTES.checkIn.path(":reservationId"), element: <CheckInPage /> },
        { path: "*", element: <NoPageFoundPage /> }
      ],
    },
    { path: ROUTES.slackRedirect.path, element: <SlackRedirectPage /> },
  ])

  return (
    <Configurator configurators={[
      AuthConfigurator,
      RelayConfigurator,
      ToastConfigurator
    ]}>
      <div style={{ height: "100%", display: "flex", flexFlow: "column" }}>
        <RouterProvider router={router} />
      </div>
    </Configurator>
  );
}

export const ROUTES = {
  home: { path: "/" },
  login: {
    path: "/login",
    withRedirectBack: () => {
      return ROUTES.login.path + "?redirect=" + window.location.pathname + window.location.search
    }
  },
  userHome: { path: "/userHome" },
  userSettings: { path: "/userSettings" },
  office: {
    path: (officeId: string) => `/office/${officeId}`,
  },
  checkIn: {
    path: (reservationId: string) => `/checkin/${reservationId}`,
  },
  slackRedirect: { path: "/slackRedirect" }
}

export default App;
