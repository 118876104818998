import { ReactNode } from "react"

interface Props {
    configurators: ((props: { children: JSX.Element }) => JSX.Element)[],
    children: ReactNode
}

/**
 * Configurators (Kind of a weird name) are a way to wrap the 
 * root element of the app in a bunch of nested layers without having to
 * actually nest them all in.
 */
export function Configurator(props: Props) {
    const [Head, ...tail] = props.configurators

    if (Head === undefined) {
        return (
            <>
                {props.children}
            </>
        )
    }

    return (
        <Head>
            <Configurator
                configurators={tail}
                children={props.children}
            />
        </Head>
    )
}