import { useContext } from "react"
import { Link } from "react-router-dom"
import { ROUTES } from "../../App"
import { AuthenticationStatusContext } from "../../config/AuthConfigurator"

interface Props {
    onClick?: () => void
}

export function SignInOrUserHomeButton(props: Props) {
    const authenticationStatus = useContext(AuthenticationStatusContext)

    if (authenticationStatus.tokenData == null) {
        return (
            <Link
                to={ROUTES.login.withRedirectBack()}
                onClick={props.onClick}
                className="button is-info">
                Sign In
            </Link>
        )
    }

    return (
        <Link
            onClick={props.onClick}
            to={ROUTES.userHome.path}
            className="button is-info">
            {authenticationStatus.tokenData.userName}
        </Link>
    )
}
