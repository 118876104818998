/**
 * @generated SignedSource<<dbcf9a15bc7502f48ca9862c9a35b3e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DayInput = {
  day: number;
  month: number;
  year: number;
};
export type OfficeQuery$variables = {
  day: DayInput;
  officeId: string;
};
export type OfficeQuery$data = {
  readonly currentUser: {
    readonly company: {
      readonly " $fragmentSpreads": FragmentRefs<"OfficeSelector">;
    };
    readonly id: string;
  };
  readonly office: {
    readonly desks: ReadonlyArray<{
      readonly id: string;
      readonly mapPositionX: number;
      readonly mapPositionY: number;
      readonly name: string;
      readonly reservation: {
        readonly deskId: string;
        readonly userId: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"DeskDetailsModal" | "DeskPin">;
    }>;
    readonly name: string;
    readonly officeMapName: string;
  } | null;
};
export type OfficeQuery = {
  response: OfficeQuery$data;
  variables: OfficeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "day"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "officeId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "officeId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "officeMapName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mapPositionX",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mapPositionY",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "day",
    "variableName": "day"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deskId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OfficeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OfficeSelector"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Office",
        "kind": "LinkedField",
        "name": "office",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Desk",
            "kind": "LinkedField",
            "name": "desks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "Reservation",
                "kind": "LinkedField",
                "name": "reservation",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": (v8/*: any*/),
                "kind": "FragmentSpread",
                "name": "DeskPin"
              },
              {
                "args": (v8/*: any*/),
                "kind": "FragmentSpread",
                "name": "DeskDetailsModal"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OfficeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Office",
                "kind": "LinkedField",
                "name": "offices",
                "plural": true,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Office",
        "kind": "LinkedField",
        "name": "office",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Desk",
            "kind": "LinkedField",
            "name": "desks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "Reservation",
                "kind": "LinkedField",
                "name": "reservation",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Day",
                    "kind": "LinkedField",
                    "name": "day",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "day",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "month",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "year",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkedIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checkInNotificationSent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c354644764e9f4b4790be62e6e4e0a31",
    "id": null,
    "metadata": {},
    "name": "OfficeQuery",
    "operationKind": "query",
    "text": "query OfficeQuery(\n  $officeId: String!\n  $day: DayInput!\n) {\n  currentUser {\n    id\n    company {\n      ...OfficeSelector\n    }\n  }\n  office(id: $officeId) {\n    name\n    officeMapName\n    desks {\n      id\n      name\n      mapPositionX\n      mapPositionY\n      reservation(day: $day) {\n        deskId\n        userId\n      }\n      ...DeskPin_1Nqzrl\n      ...DeskDetailsModal_1Nqzrl\n    }\n  }\n}\n\nfragment DeskDetailsModal_1Nqzrl on Desk {\n  id\n  name\n  reservation(day: $day) {\n    ...ReservationDetails\n  }\n}\n\nfragment DeskPin_1Nqzrl on Desk {\n  name\n  reservation(day: $day) {\n    id\n    user {\n      id\n      name\n    }\n  }\n}\n\nfragment OfficeSelector on Company {\n  offices {\n    id\n    name\n  }\n}\n\nfragment ReservationDetails on Reservation {\n  id\n  user {\n    id\n    name\n  }\n  day {\n    day\n    month\n    year\n  }\n  checkedIn\n  checkInNotificationSent\n}\n"
  }
};
})();

(node as any).hash = "84b7cc6365346099d5bb3c49906c9a11";

export default node;
