interface Props {
    userName: String,
    sizePx: number,
}

export function UserIcon(props: Props) {

    function calculateInitials() {
        const words = props.userName.split(" ")
        if (words.length >= 2) {
            return words[0].charAt(0) + words[1].charAt(0)
        } else {
            return props.userName.substring(0, 2)
        }
    }

    return (
        <div style={{
            height: `${props.sizePx}px`,
            width: `${props.sizePx}px`,
            lineHeight: `${props.sizePx}px`,
            backgroundColor: "blue",
            color: "white",
            borderRadius: "50%",
            textAlign: "center"
        }}>
            {calculateInitials().toLocaleUpperCase()}
        </div >
    )
}