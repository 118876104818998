/**
 * @generated SignedSource<<359def6f761c30bea5e0889adf7630f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckInReservation$data = {
  readonly checkedIn: boolean;
  readonly day: {
    readonly day: number;
    readonly month: number;
    readonly year: number;
  };
  readonly desk: {
    readonly name: string;
    readonly office: {
      readonly name: string;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "CheckInReservation";
};
export type CheckInReservation$key = {
  readonly " $data"?: CheckInReservation$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckInReservation">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckInReservation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkedIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Desk",
      "kind": "LinkedField",
      "name": "desk",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Office",
          "kind": "LinkedField",
          "name": "office",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Day",
      "kind": "LinkedField",
      "name": "day",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "month",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Reservation",
  "abstractKey": null
};
})();

(node as any).hash = "3c96e13fb45ec7e2c82a91b03f889ba4";

export default node;
