/**
 * @generated SignedSource<<5f048136c25cafac83f91701021dc4fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingsQuery$variables = {};
export type UserSettingsQuery$data = {
  readonly currentUser: {
    readonly notificationPreferences: {
      readonly " $fragmentSpreads": FragmentRefs<"UserNotificationPreferences">;
    };
  };
};
export type UserSettingsQuery = {
  response: UserSettingsQuery$data;
  variables: UserSettingsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationPreferences",
            "kind": "LinkedField",
            "name": "notificationPreferences",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserNotificationPreferences"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotificationPreferences",
            "kind": "LinkedField",
            "name": "notificationPreferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkInNotificationEmail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "checkInNotificationSlack",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97f8728858f7f44028ea223417e3aa4b",
    "id": null,
    "metadata": {},
    "name": "UserSettingsQuery",
    "operationKind": "query",
    "text": "query UserSettingsQuery {\n  currentUser {\n    notificationPreferences {\n      ...UserNotificationPreferences\n    }\n  }\n}\n\nfragment UserNotificationPreferences on UserNotificationPreferences {\n  checkInNotificationEmail\n  checkInNotificationSlack\n}\n"
  }
};

(node as any).hash = "4b6cb88f4f4640351566d50617ea11ee";

export default node;
