import { graphql } from "babel-plugin-relay/macro"
import { UserNotificationPreferences } from "./UserNotificationPreferences"
import { useLazyLoadQuery } from "react-relay"
import { UserSettingsQuery } from "./__generated__/UserSettingsQuery.graphql"

export function UserSettings() {
    const data = useLazyLoadQuery<UserSettingsQuery>(
        graphql`
            query UserSettingsQuery {
                currentUser {
                    notificationPreferences {
                        ...UserNotificationPreferences
                    }
                }
            } 
        `,
        {}
    )

    return (
        <section className="section">
            <UserNotificationPreferences userNotificationPreferencesKey={data.currentUser.notificationPreferences} />
        </section>
    )
}