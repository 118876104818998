import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay';
import { UserQuery } from './__generated__/UserQuery.graphql'
import { ROUTES } from '../App';

interface Props {
}

export function User(props: Props) {
    const data = useLazyLoadQuery<UserQuery>(
        graphql`
            query UserQuery {
                currentUser {
                    name
                    email
                    company {
                        name
                        offices {
                            id
                            name
                        }
                    }
                }
            }
        `,
        {}
    )

    const user = data.currentUser
    if (user == null) {
        return (
            <div>
                <h1>User not found!?!</h1>
            </div>
        )
    }
    const company = user.company
    const offices = company.offices

    return (
        <div>
            <p>
                Hello {user.name}, your email is: {user.email}. Your company is called {user.company.name}
            </p>
            <div>
                Your company has offices:
                <ul>
                    {offices.map(office => (
                        <li key={office.id}>
                            <a href={ROUTES.office.path(office.id)}>
                                {office.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}