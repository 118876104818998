import React from 'react'

interface Props {
    name: string
}

export function DeskPinLoading(props: Props) {
    return (
        <button className={"button is-rounded is-loading"}>
            {props.name}
        </button>
    )
}
