import { useContext, useState } from "react"
import { AuthenticationStatusContext } from "../config/AuthConfigurator"
import { ROUTES } from "../App"
import { ReactComponent as DeskIcon } from "../assets/desk-icon.svg"
import { SignOutButton } from "./auth/SignOutButton"
import { SignInOrUserHomeButton } from "./auth/SignInOrUserHomeButton"

export function Navbar() {
    const authenticationStatus = useContext(AuthenticationStatusContext)
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <nav className="navbar has-shadow">
            <div className="navbar-brand">
                <a className="navbar-item" href={ROUTES.home.path}>
                    <DeskIcon />
                    <div className="navbar-item">
                        Deskinn
                    </div>
                </a>
                <button
                    className={"navbar-burger" + (menuOpen ? " is-active" : "")}
                    onClick={() => setMenuOpen(!menuOpen)}
                    aria-label="menu" aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
                <div className="navbar-start"></div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <SignInOrUserHomeButton onClick={() => setMenuOpen(false)} />
                    </div>
                    {authenticationStatus.tokenData != null && (
                        <div className="navbar-item">
                            <SignOutButton onClick={() => setMenuOpen(false)} />
                        </div>
                    )}
                </div>
            </div>
        </nav>
    )
}