/**
 * @generated SignedSource<<7a84fdd3c421fd983d3a6a46211e3fcc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CheckInMutation$variables = {
  reservationId: string;
};
export type CheckInMutation$data = {
  readonly checkInReservation: {
    readonly checkedIn: boolean;
    readonly day: {
      readonly day: number;
      readonly month: number;
      readonly year: number;
    };
    readonly desk: {
      readonly name: string;
      readonly office: {
        readonly name: string;
      };
    };
    readonly id: string;
  } | null;
};
export type CheckInMutation = {
  response: CheckInMutation$data;
  variables: CheckInMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reservationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reservationId",
        "variableName": "reservationId"
      }
    ],
    "concreteType": "Reservation",
    "kind": "LinkedField",
    "name": "checkInReservation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checkedIn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Desk",
        "kind": "LinkedField",
        "name": "desk",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Office",
            "kind": "LinkedField",
            "name": "office",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Day",
        "kind": "LinkedField",
        "name": "day",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "day",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckInMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4a40b7e82f28bbae1cf0695d42fde248",
    "id": null,
    "metadata": {},
    "name": "CheckInMutation",
    "operationKind": "mutation",
    "text": "mutation CheckInMutation(\n  $reservationId: String!\n) {\n  checkInReservation(reservationId: $reservationId) {\n    id\n    checkedIn\n    desk {\n      name\n      office {\n        name\n      }\n    }\n    day {\n      day\n      month\n      year\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e681babf9fab7fd4bc1d442cb484977";

export default node;
