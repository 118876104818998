import { Suspense } from "react";
import { useParams } from "react-router-dom";
import { AuthRequired } from "../auth/AuthRequired";
import { CheckIn } from "../components/reservation/CheckIn";

export function CheckInPage() {
    const { reservationId } = useParams()

    if (reservationId === undefined) {
        return <div>NO RESERVATION?!</div>
    }

    return (
        <AuthRequired>
            <Suspense fallback={"loading..."}>
                <CheckIn reservationId={reservationId} />
            </Suspense>
        </AuthRequired>
    )
}