import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro"
import { UserNotificationPreferences$key } from "./__generated__/UserNotificationPreferences.graphql";
import { useState } from "react";

interface Props {
    userNotificationPreferencesKey: UserNotificationPreferences$key
}

export function UserNotificationPreferences(props: Props) {
    const data = useFragment(
        graphql`
        fragment UserNotificationPreferences on UserNotificationPreferences {
                checkInNotificationEmail
                checkInNotificationSlack
        }
        `,
        props.userNotificationPreferencesKey
    )
    const [save, savePending] = useMutation(
        graphql`
            mutation UserNotificationPreferencesMutation(
                $input: SetUserNotificationPreferencesRequestInput!
            ) {
                setUserNotificationPreferences(request: $input) {
                    ...UserNotificationPreferences
                }
            }
        `
    )

    const [checkInNotificationEmail, setCheckInNotificationEmail] = useState(data.checkInNotificationEmail)
    const [checkInNotificationSlack, setCheckInNotificationSlack] = useState(data.checkInNotificationSlack)

    function onSave() {
        save({
            variables: {
                input: {
                    checkInNotificationEmail: checkInNotificationEmail,
                    checkInNotificationSlack: checkInNotificationSlack,
                }
            }
        })
    }

    return (
        <div>
            <h1 className="title">
                Notification Settings
            </h1>
            Email:
            <input
                className="checkbox"
                type="checkbox"
                checked={checkInNotificationEmail}
                onChange={e => setCheckInNotificationEmail(e.target.checked)} />
            Slack:
            <input
                className="checkbox"
                type="checkbox"
                checked={checkInNotificationSlack}
                onChange={e => setCheckInNotificationSlack(e.target.checked)} />
            <button
                className="button"
                disabled={savePending}
                onClick={onSave}>
                Save
            </button>
        </div>
    )
}