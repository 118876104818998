import { ReactElement, useContext } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { RequestParameters, Variables, GraphQLResponse, Environment, Network, Store, RecordSource } from 'relay-runtime';
import { AuthenticationStatusContext } from "./AuthConfigurator";

interface Props {
    children: ReactElement
}

export function RelayConfigurator(props: Props) {

    const authenticationStatus = useContext(AuthenticationStatusContext)

    async function fetchQuery(
        params: RequestParameters,
        variables: Variables,
    ): Promise<GraphQLResponse> {

        const response = await fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authenticationStatus.tokenData?.token,
            },
            body: JSON.stringify({
                query: params.text,
                variables,
            }),
        });

        if (response.status === 401) {
            authenticationStatus.setToken(null)
            return {
                errors: [
                    {
                        message: "Not authorized"
                    }
                ]
            }
        }

        return response.json();
    }

    const environment: Environment = new Environment({
        network: Network.create(fetchQuery),
        store: new Store(new RecordSource()),
    });

    return (
        <RelayEnvironmentProvider environment={environment}>
            {props.children}
        </RelayEnvironmentProvider>
    )
}