import { graphql } from "babel-plugin-relay/macro"
import { useMutation } from "react-relay"
import { ReserveDeskButtonMutation } from "./__generated__/ReserveDeskButtonMutation.graphql"
import { dayToGql } from "../../model/DateRange"
import { Dayjs } from "dayjs"
import { useToast } from "../../config/ToastConfigurator"

interface Props {
    deskId: string,
    day: Dayjs,
}

export function ReserveDeskButton(props: Props) {
    const [reserve, pending] = useMutation<ReserveDeskButtonMutation>(
        graphql`
            mutation ReserveDeskButtonMutation(
                $input: ReservationRequestInput!,
                $day: DayInput!
                ) {
                requestReservation(request: $input) {
                    desk {
                        ...DeskPin @arguments(day: $day)
                        ...DeskDetailsModal @arguments(day: $day)
                    }
                }
            } 
        `
    )
    const toast = useToast()

    return (
        <button
            className="button"
            onClick={() => reserve({
                variables: {
                    input: {
                        deskId: props.deskId,
                        day: dayToGql(props.day),
                    },
                    day: dayToGql(props.day)
                },
                onCompleted(_, errors) {
                    if (errors != null) {
                        const messages = errors.map(e => e.message).join(", ")
                        toast.useToast(":( Failed to reserve desk: " + messages, "error")
                    }
                },
                onError: e => console.log(e)
            })}
            disabled={pending}
        >
            Reserve
        </button>
    )
}