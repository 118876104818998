import { ReactElement, useContext, useEffect } from "react";
import { AuthenticationStatusContext } from "../config/AuthConfigurator";
import { ROUTES } from "../App";
import { useNavigate } from "react-router-dom";

interface Props {
    children: ReactElement
}

export function AuthRequired(props: Props) {
    const authenticationStatus = useContext(AuthenticationStatusContext)
    const noAuth = authenticationStatus.tokenData == null
    const navigate = useNavigate()
    useEffect(() => {
        if (noAuth) {
            navigate(ROUTES.login.withRedirectBack())
        }
    })

    return noAuth ? <div>Rerouting</div> : props.children
}
