/**
 * @generated SignedSource<<7b9dacdd10d3a3d0f6717128502b50f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeskPin$data = {
  readonly name: string;
  readonly reservation: {
    readonly id: string;
    readonly user: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
  readonly " $fragmentType": "DeskPin";
};
export type DeskPin$key = {
  readonly " $data"?: DeskPin$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeskPin">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "day"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeskPin",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "day",
          "variableName": "day"
        }
      ],
      "concreteType": "Reservation",
      "kind": "LinkedField",
      "name": "reservation",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Desk",
  "abstractKey": null
};
})();

(node as any).hash = "07f5448401d747acbe0fd4fdf250d3a8";

export default node;
