import { FormEvent, useContext, useEffect, useState } from "react";
import { AuthenticationStatusContext } from "../config/AuthConfigurator";
import { GetTokenErrorResponse, getToken } from "../auth/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../App";

export function LoginPage() {

    const authenticationStatus = useContext(AuthenticationStatusContext)
    const loggedIn = authenticationStatus.tokenData != null

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loggingIn, setLoggingIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const navigate = useNavigate()
    const [queryParams] = useSearchParams()
    const redirectUrl = queryParams.get("redirect")

    useEffect(() => {
        if (loggedIn) {
            navigate(redirectUrl ?? ROUTES.home.path)
        }
    })

    async function onSubmit(formEvent: FormEvent) {
        formEvent.preventDefault();
        setLoggingIn(true)
        const result = await getToken({
            username,
            password
        })
        if ("error" in result) {
            setErrorMessage(getErrorMessage(result))
        } else {
            authenticationStatus.setToken(result.token)
        }
        setLoggingIn(false)
    }

    function getErrorMessage(response: GetTokenErrorResponse): string {
        switch (response.error) {
            case "invalid_credentials": return "Username and password not recognized."
            // TODO error reporting
            case "internal_error": return "Internal error. Please try again."
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="box" style={{ width: 400 }}>
                <h3 className="title">
                    Login
                </h3>
                <form onSubmit={onSubmit}>
                    <fieldset disabled={loggingIn}>
                        <div className="field">
                            <label className="label" htmlFor="username">Username</label>
                            <input className="input" type="text" autoCapitalize="off" autoFocus={true} id="username" value={username} onChange={e => setUsername(e.target.value)} />
                        </div>
                        <div className="field">
                            <label className="label" htmlFor="password">Password</label>
                            <input className="input" type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        {errorMessage && <p className="help is-danger">{errorMessage}</p>}
                        <div className="level" style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <button className="button is-primary" type="submit">Login</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    )
}
