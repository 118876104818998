import React, { Suspense, useMemo } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay';
import { OfficeQuery } from './__generated__/OfficeQuery.graphql'
import { dayToGql } from '../../model/DateRange';
import { DateSelector } from '../DateSelector';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { DeskPin } from '../desk/DeskPin';
import { DeskPinLoading } from '../desk/DeskPinLoading';
import { Dayjs } from 'dayjs';
import { OfficeSelector } from './OfficeSelector';
import { DeskDetailsModal } from '../desk/DeskDetailsModal';

interface Props {
    officeId: string,
    selectedDeskId: string | null,
    setSelectedDeskId: (id: string | null) => void,
    day: Dayjs,
    setDay: (day: Dayjs) => void,
}

export function Office(props: Props) {
    const data = useLazyLoadQuery<OfficeQuery>(
        graphql`
            query OfficeQuery($officeId: String!, $day: DayInput!) {
                currentUser {
                    id
                    company {
                        ...OfficeSelector
                    }
                }
                office(id: $officeId) {
                    name
                    officeMapName
                    desks {
                        id
                        name
                        mapPositionX
                        mapPositionY
                        reservation(day: $day) {
                            deskId
                            userId
                        }
                        ...DeskPin @arguments(day: $day)
                        ...DeskDetailsModal @arguments(day: $day)
                    }
                }
            }
        `,
        {
            officeId: props.officeId,
            day: dayToGql(props.day),
        }
    )
    const desks = data.office?.desks
    const selectedDesk = useMemo(() => {
        return desks?.find(desk => desk.id === props.selectedDeskId)
    }, [
        desks,
        props.selectedDeskId
    ])
    const userReservedDesk = desks?.filter(d => d.reservation?.userId === data.currentUser.id)?.[0]

    const office = data.office;
    if (office == null) {
        return <div>
            Office not found :(
        </div>
    }

    return (
        // TODO take up the remaining height exactly
        <div style={{ height: "90%" }}>
            {selectedDesk != null && (
                <DeskDetailsModal
                    deskKey={selectedDesk}
                    day={props.day}
                    userId={data.currentUser.id}
                    reservedDesk={userReservedDesk == null ? null : {
                        id: userReservedDesk.id,
                        name: userReservedDesk.name
                    }}
                    onClose={() => props.setSelectedDeskId(null)} />
            )}

            <div
                className="level is-mobile"
                style={{ position: "absolute", zIndex: 100, width: "100%", padding: "8px" }}>
                <div className="level-left">
                    <div className="level-item">
                        <div className="buttons">
                            <OfficeSelector
                                officeId={props.officeId}
                                companyKey={data.currentUser.company} />
                            <DateSelector value={props.day} onChange={props.setDay} />
                        </div>
                    </div>
                </div>
            </div >
            <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
                <TransformWrapper minScale={0.2} centerOnInit={true}>
                    <TransformComponent>
                        <img
                            src={`/officemaps/${office.officeMapName}`}
                            alt='An office floor plan'
                            style={{ width: "600px", height: "600px" }}
                        />
                        {
                            office.desks.map(desk => (
                                <div key={desk.id}
                                    style={{
                                        position: 'fixed',
                                        left: desk.mapPositionX,
                                        top: desk.mapPositionY,
                                    }}>
                                    <Suspense fallback={(<DeskPinLoading name={desk.name} />)}>
                                        <DeskPin
                                            deskKey={desk}
                                            onClick={() => props.setSelectedDeskId(props.selectedDeskId === desk.id ? null : desk.id)} />
                                    </Suspense>
                                </div>
                            ))
                        }
                    </TransformComponent>
                </TransformWrapper>
            </div>
        </div >
    )
}