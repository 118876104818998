import { Suspense } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Office } from "../components/office/Office";
import { AuthRequired } from "../auth/AuthRequired";
import dayjs from "dayjs";

const selectedDeskIdKey = "desk"
const dayKey = "day"

export function OfficePage() {
    const { officeId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedDeskId = searchParams.get(selectedDeskIdKey)
    const dayString = searchParams.get(dayKey)
    const day = dayString == null ? dayjs() : dayjs(dayString)

    if (officeId === undefined) {
        return <div>NO OFFICE?!</div>
    }

    return (
        <AuthRequired>
            <Suspense fallback={"loading..."}>
                <Office
                    officeId={officeId}
                    selectedDeskId={selectedDeskId}
                    setSelectedDeskId={id => {
                        if (id == null) {
                            searchParams.delete(selectedDeskIdKey)
                        } else {
                            searchParams.set(selectedDeskIdKey, id)
                        }
                        setSearchParams(searchParams)
                    }}
                    day={day}
                    setDay={day => {
                        searchParams.set(dayKey, day.format("YYYY-MM-DD"))
                        setSearchParams(searchParams)
                    }}
                />
            </Suspense>
        </AuthRequired>
    )
}