import { graphql } from "babel-plugin-relay/macro"
import { useMutation } from "react-relay";
import { AuthRequired } from "../auth/AuthRequired";
import { useEffect, useState } from "react";
import { SlackRedirectPageMutation } from "./__generated__/SlackRedirectPageMutation.graphql";
import { useSearchParams } from "react-router-dom";

export function SlackRedirectPage() {
    const [queryParams] = useSearchParams()
    const code = queryParams.get("code")
    const state = queryParams.get("state")

    const [hasFailed, setHasFailed] = useState(false)

    const [authorize, authorizePending] = useMutation<SlackRedirectPageMutation>(
        graphql`
            mutation SlackRedirectPageMutation(
                $input: AuthorizeWithSlackRequestInput!
            ) {
                authorizeWithSlack(request: $input) {
                    success
                }
            }
        `
    )

    useEffect(() => {
        if (code == null || state == null) {
            setHasFailed(true)
            return
        }

        const disposable = authorize({
            variables: {
                input: {
                    code: code,
                    state: state,
                    redirectUri: window.location.origin + window.location.pathname
                }
            },
            onCompleted: (response, errors) => {
                if (response.authorizeWithSlack.success !== true || (errors != null && errors.length > 0)) {
                    setHasFailed(true)
                }
            },
            onError: () => {
                setHasFailed(true)
            }
        })

        return disposable.dispose
    }, [code, state, authorize])

    const title = authorizePending ? "Authorizing with slack..."
        : (hasFailed ? "Failed to authorize with slack. Please try again." : "Successfully authorized!")

    return (
        <AuthRequired>
            <section className="hero">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <p className="title">
                            {title}
                        </p>
                        <p className="subtitle">
                            <button
                                className="button"
                                onClick={() => window.close()}
                            >
                                Close Window
                            </button>
                        </p>
                    </div>
                </div>
            </section>
        </AuthRequired>
    )
}