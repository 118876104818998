import { Suspense } from "react";
import { User } from "../components/User";
import { AuthRequired } from "../auth/AuthRequired";

export function UserHomePage() {
    return (
        <AuthRequired>
            <Suspense fallback={"loading..."}>
                <User />
            </Suspense>
        </AuthRequired>
    )
}