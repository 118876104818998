import { ReactElement, createContext, useContext, useState } from "react";

interface Props {
    children: ReactElement
}

interface Toast {
    useToast: (
        message: string,
        intent: ToastIntent
    ) => void
}

type ToastIntent =
    "info" |
    "success" |
    "warning" |
    "error"

const cssClassForIntent: Record<ToastIntent, string> = {
    "info": "is-info",
    "success": "is-success",
    "warning": "is-warning",
    "error": "is-danger"
}

const ToastContext = createContext<Toast>({
    useToast: () => { }
})

export function useToast() {
    return useContext(ToastContext)
}

export function ToastConfigurator(props: Props) {
    const [message, setMessage] = useState<string | null>(null)
    const [intent, setIntent] = useState<ToastIntent>("info")

    return (
        <ToastContext.Provider value={{
            useToast: (message, intent) => {
                setMessage(message)
                setIntent(intent)

                setTimeout(() => setMessage(null), 8000)
            }
        }}>
            {message != null && (
                <div style={{ position: "absolute", zIndex: 9999, width: "100%", padding: "8px" }}>
                    <div className="container">
                        <div className={`notification ${cssClassForIntent[intent]}`}>
                            <button className="delete" onClick={() => setMessage(null)} />
                            <div style={{ fontSize: "18px" }}>
                                {message}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {props.children}
        </ToastContext.Provider>
    )
}